import React, { useState } from 'react'
import DashboardLayout from '../../Components/Layouts/DashBoardLayout';
import { dispachterdata } from '../Dashboard';
import { CardTitle } from '../../Components/CustomComponents'
import DispatcherCard from '../../Components/Cards/DispatcherCard'
import { Col, Row } from 'react-bootstrap'
import NomalDispatcherModal from '../../Components/Modals/NormalModal';


const Normal = () => {
    const filteredNormalDispatcher = dispachterdata.filter(item => item.beat_count < 150 && item.beat_count > 60 );
    const [normalModal, setNormalModal] = useState(false)

    const handleModalClose = () => {
      setNormalModal(prev => !prev);
  };
  
  return (
    <DashboardLayout>
        <section>
        <CardTitle text='Normal' classes="text-black fs-5 fw-bold mb-2"/>
        <Row className={`mb-5`}>
        {dispachterdata.length > 0 && (
            filteredNormalDispatcher.map((item) => (
                <Col className={`mt-2 mb-2 curs`} lg={4} md={4} sm={6} key={item.id} onClick={handleModalClose}>
                <DispatcherCard data={item} />
                </Col>
            ))
        )}
        </Row>
        </section>
        {normalModal ? (
          <NomalDispatcherModal
            handleClose={handleModalClose}
            data={''}
          />
        ) : null}
    </DashboardLayout>
  )
}

export default Normal;