import React from "react";
import styles from "./style/Dispatcher.module.scss";
import { VitalsGraph } from "../../Components/Modals/VitalModal";
import { CardTitle } from "../../Components/CustomComponents";
import { Link, useParams } from "react-router-dom";
import { dispachterdata } from "../Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { FaSitemap, FaXmark } from "react-icons/fa6";
import { toggleHeatMap } from "../../redux/slices/heatMap";

const Dispatcher = () => {
  const { id } = useParams();
  const getDispactherData = dispachterdata.find(
    (item) => item.id === Number(id)
  );
  console.log(getDispactherData);
  const heatMap = useSelector((state) => state.heatMap);
  const dispatch = useDispatch();
  const handleBack = () => {
    dispatch(toggleHeatMap());
  };

  return (
    <>
      {(heatMap && getDispactherData.beat_count > 150) ? (
        <main className={styles.HeatMap}>
          <p className={`${styles.xmark} bg-opacity-75 d-flex p-3 rounded-5`}>
            <FaXmark
              role="button"
              onClick={handleBack}
              style={{ cursor: "pointer" }}
            />
          </p>
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345092064!2d144.963057615868!3d-37.81627974201416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d87cb2b2fddf!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1633044182654!5m2!1sen!2sau"
              width="100%"
              height="100%"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen=""
              loading="lazy"
              title="Map"
            ></iframe>
          </div>
        </main>
      ) : (
        <div
          className={`${
            getDispactherData.beat_count > 150 ? "max-w-lg-75" : ""
          } ${styles.vitalsModal}`}
        >
          <div className="d-flex align-items-center justify-content-between w-100 mb-4">
            <div className={styles.vitalleft}>
              <div className="w-100 d-flex justify-content-center">
                <div className={styles.header}>
                  <img
                    src={getDispactherData.profile_img}
                    alt="Officer"
                    className={styles.profileImage}
                  />
                  <div className={styles.userInfo}>
                    <h5>{getDispactherData.profile_name}s</h5>
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.infoSection}>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Badge:</strong>
                    </p>
                    <p className="text-black">1156</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Last Report:</strong>
                    </p>
                    <p className="text-black">Dec 14, 10:23:23</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>HR:</strong>
                    </p>
                    <p className="text-black">
                      <span className={styles.highLighted}>185 bpm</span>
                    </p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Temperature:</strong>
                    </p>
                    <p className="text-black">-1 F</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Motion:</strong>
                    </p>
                    <p className="text-black">-1</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Perspiration:</strong>
                    </p>
                    <p className="text-black">-1</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Status:</strong>
                    </p>
                    <p className="text-black">
                      <span className={styles.waiting}>
                        Waiting for Officer Response
                      </span>
                    </p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Connection Status:</strong>
                    </p>
                    <p className="text-black">
                      <span className={styles.connected}>Connected</span>
                    </p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Status Duration:</strong>
                    </p>
                    <p className="text-black">00:00:05</p>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <button className={styles.officerOk}>
                  <p>Officer is OK</p>
                </button>
                <button className={styles.assistNow}>
                  <p>Assist Now</p>
                </button>
              </div>
            </div>
            {getDispactherData.beat_count > 150 && (
              <div className="w-50 h-100 relative">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345092064!2d144.963057615868!3d-37.81627974201416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d87cb2b2fddf!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1633044182654!5m2!1sen!2sau"
                  width="100%"
                  height="425px"
                  style={{ border: 0, borderRadius: "10px" }}
                  allowFullScreen=""
                  loading="lazy"
                  title="Map"
                ></iframe>
                <p
                  className={`${styles.xmark} bg-opacity-75`}
                  title={heatMap ? "Heat Map" : "Toggle To View Heat Map"}
                  onClick={handleBack}
                >
                  {/* <Link
                    to={
                      heatMap &&
                      `${process.env.REACT_APP_DOMAIN_DIR}/heat-map/${getDispactherData.id}`
                    }
                  > */}
                    <FaSitemap
                      role="button"
                      style={{ cursor: heatMap && "pointer", color: "black" }}
                    />
                  {/* </Link> */}
                </p>
              </div>
            )}
          </div>
          {getDispactherData.beat_count > 150 && (
            <div className={styles.vitalsGraph}>
              <div className={styles.graphDiv}>
                <CardTitle text="Vitals" classes="text-black fs-5 fw-bold" />
                <p className="m-0 fs-6">Heart rate: 185</p>
                <VitalsGraph />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Dispatcher;
