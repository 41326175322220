import React from "react";

import styles from "../../Pages/Dashboard/styles/Dashboard.module.scss";

import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
import { BiSolidDroplet } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import DashboardCard from "../../Components/Cards/DashboardCard";

export default function DashboardLayout({ children }) {

  return (
    <div className={styles.Dashboard}>
      <Row className={`mb-4`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<BiSolidDroplet color="#23AD23" />}
            number={256}
            text={"Normal"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<BiSolidDroplet color="#FE740B" />}
            number={73}
            text={"Security Check"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<BiSolidDroplet color="#FF0000" />}
            number={'09'}
            text={"Emergency"}
          />
        </Col>
      </Row>

      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Officers List"} />
        </div>
        <div className={styles.search}>
          <input
            type="text"
            placeholder="Search Officer"
            // value={search}
            // onChange={(e) => dispatch(setValue(e.target.value))}
            />
          <div className={styles.searchButton}>
            <AiOutlineSearch color="#D2D2D2" />
          </div>
        </div>
      </div>
      <section className="mt-lg-4">
      {children}
      </section>
    </div>
  );
}
