import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

// global scss for globally styles and fonts
import './_fonts.scss';
import './_global.scss';

import { WEB_ROUTES } from "./constants.js";
import { baseRoute } from "./Helper";
import PublicRoute from "./Components/Routes/PublicRoute.js";
import Account from "./Pages/Account";
import ProtectedLayout from "./Components/Layouts/ProtectedLayout.js";
import Dashboard from "./Pages/Dashboard";
import Setting from "./Pages/Setting";
import ProtectedRoute from "./Components/Routes/ProtectedRoute.js";
import { uniqueId } from "lodash";
import Normal from "./Pages/Normal/index.jsx";
import Alert from "./Pages/Alert/index.jsx";
import Emergency from "./Pages/Emergency/index.jsx";
import BookingHistory from "./Pages/Booking-History/index.js";
import Dispatcher from "./Pages/Dispatcher/index.js";
import HeatMap from "./Pages/HeatMap/index.js";
function App() {
  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.ACCOUNT)} element={
        // <PublicRoute >
          <Account />
        // </PublicRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.BOOKINGHISTORY)} element={
        // <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={0} onBooking={true}>
            <BookingHistory />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={`${baseRoute(WEB_ROUTES.BOOKINGHISTORY)}/:id`} element={
        // <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={0} onBooking={true}>
            <Dispatcher />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={`${baseRoute(WEB_ROUTES.HEATMAP)}/:id`} element={
        // <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={''} onBooking={true}>
            <HeatMap />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.DASHBOARD)} element={
        // <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={0}>
            <Dashboard />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.NORMAL)} element={
        // <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={1}>
            <Normal />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.ALERT)} element={
        // <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={2}>
           <Alert />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.EMERGENCY)} element={
        // <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={3}>
            <Emergency />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.SETTING)} element={
        // <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={4}>
            <Setting />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
    ]

  }, [])

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route path="/" element={<Account />} />
      </Routes >


    </Router >
  );
}

export default App;
