import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  comments: [],
};

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    addComment: (state, action) => {
      state.comments.push(action.payload);
    },
    likeComment: (state, action) => {
      const comment = state.comments.find(comment => comment.id === action.payload);
      if (comment) comment.likes += 1;
    },
    replyToComment: (state, action) => {
      const { commentId, reply } = action.payload;
      const comment = state.comments.find(comment => comment.id === commentId);
      if (comment) comment.replies.push(reply);
    },
  },
});

export const { addComment, likeComment, replyToComment } = commentsSlice.actions;
export const commentsReducer = commentsSlice.reducer;
