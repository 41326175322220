import React from "react";
import { FaXmark } from "react-icons/fa6";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";
import { Description } from "../CustomComponents";
import { Form } from "react-bootstrap";

const SettingModal = ({ handleSettingModal }) => {
  return (
    <div>
      <ModalContainer handleClose={handleSettingModal}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 ">Settings</p>
            <span
              className={`${cssStyles.xmark} bg-opacity-75 d-flex p-3 rounded-5`}
            >
              <FaXmark
                role="button"
                onClick={handleSettingModal}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className={cssStyles.Menu}>
          {["Temperature", "Motion", "Perspiration"].map((item, i) => (
            <div className={`${cssStyles.option} my-3 fs-4`} key={i}>
              <Description text={item} classes="fs-7" />
              <Form>
                <Form.Check type="switch" />
              </Form>
            </div>
          ))}
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default SettingModal;
