import React from "react";
import { Button, ProgressBar } from "react-bootstrap";
import ModalContainer from "../ModalContainer";
import styles from "./styles/editModal.module.scss";
import { FaXmark } from "react-icons/fa6";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import "chart.js/auto";
import { CardTitle } from "../CustomComponents";
import profile from '../../assets/images/dispatcher_profile.png'

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

export default function VitalsModal({ handleClose }) {
  return (
    <ModalContainer handleClose={handleClose}>
      <div
        className={`max-w-lg-75 d-flex align-items-center justify-content-between mb-4 ${styles.vitalsModal}`}
      >
        <div className={styles.vitalleft}>
          <div className="w-100 d-flex justify-content-center">
            <div className={styles.header}>
              <img
                src={profile}
                alt="Officer"
                className={styles.profileImage}
              />
              <div className={styles.userInfo}>
                <h5>Floyd Miles</h5>
              </div>
            </div>
            <p className={`${styles.xmark} bg-opacity-75 d-flex p-3 rounded-5`}>
              <FaXmark
                role="button"
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            </p>
          </div>
          <div className={styles.content}>
            <div className={styles.infoSection}>
              <div className={styles.infoRow}>
                <p>
                  <strong>Badge:</strong>
                </p>
                <p className="text-black">1156</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Last Report:</strong>
                </p>
                <p className="text-black">Dec 14, 10:23:23</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>HR:</strong>
                </p>
                <p className="text-black">
                  <span className={styles.highLighted}>185 bpm</span>
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Temperature:</strong>
                </p>
                <p className="text-black">-1 F</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Motion:</strong>
                </p>
                <p className="text-black">-1</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Perspiration:</strong>
                </p>
                <p className="text-black">-1</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Status:</strong>
                </p>
                <p className="text-black">
                  <span className={styles.waiting}>
                    Waiting for Officer Response
                  </span>
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Connection Status:</strong>
                </p>
                <p className="text-black">
                  <span className={styles.connected}>Connected</span>
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Status Duration:</strong>
                </p>
                <p className="text-black">00:00:05</p>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <button  className={styles.officerOk}>
              <p>Officer is OK</p>
            </button>
            <button className={styles.assistNow}>
              <p>Assist Now</p>
            </button>
          </div>
        </div>
        <div className={styles.vitalsGraph}>
          <div className={styles.graphDiv}>
          <CardTitle text='Vitals' classes="text-black fs-5 fw-bold"/>
          <p className="m-0 fs-6">Heart rate: 185</p>
          <VitalsGraph />
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345092064!2d144.963057615868!3d-37.81627974201416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d87cb2b2fddf!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1633044182654!5m2!1sen!2sau"
            width="100%"
            height="230"
            style={{ border: 0  , borderRadius: '10px' }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </ModalContainer>
  );
}

export const VitalsGraph = () => {
  const data = {
    labels: ["30m", "20m", "15m", "5m"],
    datasets: [
      {
        label: "Heart Rate",
        data: [85, 100, 150, 185], // heart rate values
        borderColor: "red",
        fill: false,
        tension: 0.4, // to create smooth curves
      },
    ],
  };

  const options = {
    scales: {
      y: {
        min: 50,
        max: 200,
      },
    },
  };

  return <Line data={data} options={options} />;
};
