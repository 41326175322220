import React from "react";
import { FaXmark } from "react-icons/fa6";
import ModalContainer from "../ModalContainer";
import styles from "./styles/editModal.module.scss";
import profile from '../../assets/images/dispatcher_profile.png'

const NomalDispatcherModal = ({ data, handleClose }) => {
  return (
    <div>
      <ModalContainer handleClose={handleClose}>
        <div
          className={`${styles.vitalsModal}`}
        >
          <div>
            <div className="d-flex justify-content-between">
            <div className="d-flex flex-grow-1 justify-content-center">
            <div className={styles.header}>
              <img
                src={profile}
                alt="Officer"
                className={styles.profileImage}
              />
              <div className={styles.userInfo}>
                <h5>Floyd Miles</h5>
              </div>
            </div>
          </div>
          <p
            className={`${styles.xmark} bg-opacity-75 d-flex p-3 rounded-5`}
          >
            <FaXmark
              role="button"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </p>
            </div>
          <div className={styles.content}>
          <div className={styles.infoSection}>
              <div className={styles.infoRow}>
                <p>
                  <strong>Badge:</strong>
                </p>
                <p className="text-black">1156</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Last Report:</strong>
                </p>
                <p className="text-black">Dec 14, 10:23:23</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>HR:</strong>
                </p>
                <p className="text-black">
                  <span className={styles.highLighted}>185 bpm</span>
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Temperature:</strong>
                </p>
                <p className="text-black">-1 F</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Motion:</strong>
                </p>
                <p className="text-black">-1</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Perspiration:</strong>
                </p>
                <p className="text-black">-1</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Status:</strong>
                </p>
                <p className="text-black">
                  <span className={styles.waiting}>
                    Waiting for Officer Response
                  </span>
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Connection Status:</strong>
                </p>
                <p className="text-black">
                  <span className={styles.connected}>Connected</span>
                </p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Status Duration:</strong>
                </p>
                <p className="text-black">00:00:05</p>
              </div>
            </div>
          </div>
          {/* <div className={styles.footer}>
            <Button variant="dark" className={styles.officerOk}>
              Officer is OK
            </Button>
            <Button variant="danger" className={styles.assistNow}>
              Assist Now
            </Button>
          </div> */}
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default NomalDispatcherModal;
