import React from "react"
import ReactDom from "react-dom";

import styles from "./index.module.scss"


export default function ModalContainer({ handleClose = () => {}, children }) {
    const modalRoot = document.getElementById('modal');
    
    if (!modalRoot) return null;

    return ReactDom.createPortal(
        <div className={styles.modalContainer}>
            <div className={styles.outside} onClick={handleClose}></div>
            {children}
        </div>,
        modalRoot
    );
}


