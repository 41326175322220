import React, { useState } from 'react'
import DashboardLayout from '../../Components/Layouts/DashBoardLayout';
import { dispachterdata } from '../Dashboard';
import { CardTitle } from '../../Components/CustomComponents'
import DispatcherCard from '../../Components/Cards/DispatcherCard'
import { Col, Row } from 'react-bootstrap'
import VitalsModal from '../../Components/Modals/VitalModal';

const Emergency = () => {
const [vitalModal, setVitalModal] = useState(false)

const handleModalClose = () => {
  setVitalModal(prev => !prev);
};
const filteredEmergencyDispatcher = dispachterdata.filter(item => item.beat_count >= 190);
  return (
    <DashboardLayout>
        <section>
        <CardTitle text='Emergency' classes="text-black fs-5 fw-bold mb-2"/>
        <Row className={`mb-5`}>
        {dispachterdata.length > 0 && (
            filteredEmergencyDispatcher.map((item) => (
                <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6} key={item.id} onClick={handleModalClose}>
                <DispatcherCard data={item} />
                </Col>
            ))
        )}
        </Row>
        </section>
        {vitalModal ? (
          <VitalsModal
            handleClose={handleModalClose}
            data={''}
          />
        ) : null}
    </DashboardLayout>
  )
}

export default Emergency;