import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

/** Import Assets */
import styles from "./styles/SideListView.module.scss";

/** Import Custom Components */
import { WEB_ROUTES } from "../../constants";
import Navbar from "./Navbar";
import { removeToken } from "../../Data/services/localStorageService";
import Toast from "../../Hooks/useToast";
import LogoutModal from "../Modals/LogoutModal";
import { SubTitle, CardTitle } from "../CustomComponents";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxDoubleArrowLeft } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaWifi } from "react-icons/fa";
import {
  dispachterdata,
  filteredAlertDispatcher,
  filteredEmergencyDispatcher,
  filteredNormalDispatcher,
} from "../../Pages/Dashboard";
import profile from '../../assets/images/dispatcher_profile.png';
import closer from '../../assets/images/closer.svg';

export default function SideListView({ handleToggle, collapsed }) {
  const [vitalModal, setVitalModal] = useState(false);
  const [normalModal, setNormalModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});

  const handleModalClose = () => {
    setVitalModal(false);
    setNormalModal(false);
    setAlertModal(false);
  };

  const toggleAccordion = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const items = [
    {
      label: "Emergency",
      component: <VitalAccordance setVitalModal={setVitalModal} />,
    },
    {
      label: "Security Check",
      component: <SecurityAccordance setAlertModal={setAlertModal} />,
    },
    {
      label: "Normal",
      component: <NormalAccordance setNormalModal={setNormalModal} />,
    },
  ];

  return (
    <div className={`${styles.sideview} d-none d-lg-block`}>
      <div className={styles.sideitem}>
        <p
          onClick={() => handleToggle((prev) => !prev)}
          className={`mb-3 fs-4 pointer`}
        >
          {collapsed ? <img src={closer} /> : <GiHamburgerMenu />}
        </p>
        <div className={styles.titleContainer}>
          <SubTitle text={"Officers List"} />
        </div>
      </div>
      <div className={styles.dispatchertype}>
        {items.map(({ label, component }, index) => (
          <div key={index}>
            <div className={`${styles.item}`}>
              <p>{label}</p>
              <p
                style={{ fontSize: "20px", display: "flex", cursor: "pointer" }}
                onClick={() => toggleAccordion(index)}
              >
                {expandedItems[index] ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </p>
            </div>
            {expandedItems[index] && (
              <div className={`${styles.accordanceItem}`}>{component}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const DispatcherList = ({ data, active }) => {
  const { id } = useParams();
  const paramid = id
  const getBeatColor = (beatCount) => {
    if (beatCount >= 190 && beatCount <= 240) {
      return "#FF0000";
    } else if (beatCount >= 150 && beatCount < 190) {
      return "#FE740B";
    } else if (beatCount >= 60 && beatCount < 150) {
      return "#23AD23";
    }
    return "#000";
  };
  console.log(active);

  return (
    <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/booking-history/${data.id}`}>
      <div className={styles.list}>
        <div className={`${styles.iconCard}`}>
          <img
            src={profile}
            alt="Officer"
            className={styles.profileImage}
          />
        </div>
        <div className="w-50 d-flex align-items-center gap-2 text-nowrap">
        <CardTitle
          text={data.profile_name}
          classes={`text-black fw-bold text-right ${styles.cardText}`}
        />
        {data.id === Number(paramid) && (
          <div
            className={styles.dot}
            style={{ background: getBeatColor(data.beat_count) }}
          ></div>
        )}
        </div>
        <p
          className="fs-5 fw-bold m-0 w-25 text-right"
          style={{ color: getBeatColor(data.beat_count) }}
        >
          {data.beat_count}
          <span className={`${styles.beatCount} text-black`}> BMP</span>
        </p>
        <FaWifi className="fiwi-icon fs-6" color="00FF00" />
      </div>
    </Link>
  );
};

const SecurityAccordance = ({ setAlertModal, alertModal }) => (
  <>
    {dispachterdata.length > 0 &&
      filteredAlertDispatcher.map((item) => (
        <div key={item.id} onClick={() => setAlertModal(true)}>
          <DispatcherList data={item} active={alertModal} />
        </div>
      ))}
  </>
);

const NormalAccordance = ({ setNormalModal, normalModal }) => (
  <>
    {dispachterdata.length > 0 &&
      filteredNormalDispatcher.map((item) => (
        <div key={item.id} onClick={() => setNormalModal(true)}>
          <DispatcherList data={item} active={normalModal} />
        </div>
      ))}
  </>
);

const VitalAccordance = ({ setVitalModal, vitalModal }) => (
  <>
    {dispachterdata.length > 0 &&
      filteredEmergencyDispatcher.map((item) => (
        <div key={item.id} onClick={() => setVitalModal(true)}>
          <DispatcherList data={item} active={vitalModal} />
        </div>
      ))}
  </>
);
