import React from "react";
import styles from "./styles/BookingHistory.module.scss";
import COSSF from "../../assets/images/COSSF.png";
import { Title } from "../../Components/CustomComponents";

const BookingHistory = () => {
  return (
    <main className={styles.booking}>
      <div className={styles.titleContainer}>
        <Title text={"LEEPS Police Department"} />
      </div>
      <img
        src={COSSF} //todo:
        alt="Logo"
        className={styles.cossf}
        width={"100%"}
        height={"60%"}
      />
    </main>
  );
};

export default BookingHistory;
