import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  posts: [],
};

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    addPost: (state, action) => {
      state.posts.push(action.payload);
    },
    likePost: (state, action) => {
      const post = state.posts.find(post => post.id === action.payload);
      if (post) post.likes += 1;
    },
    addCommentToPost: (state, action) => {
      const { postId, comment } = action.payload;
      const post = state.posts.find(post => post.id === postId);
      if (post) post.comments.push(comment);
    },
  },
});

export const { addPost, likePost, addCommentToPost } = postsSlice.actions;
export const postsReducer = postsSlice.reducer;
