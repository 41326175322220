import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react';
// import { setupListeners } from '@reduxjs/toolkit/query';

import userReducer from "./Data/features/userSlice";
import sessionMiddleware from "./Data/middleware/sessionMiddleware";

import { userApi } from "./Data/services/userApi";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    // RTK Query API
    // [userAuthApi.reducerPath]: userAuthApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    // Local storage Redux
    user: userReducer,
  },
  // load from local storage
  // preloadedState: loadFromLocalStorage(), //todo:

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  //todo:
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      // userAuthApi.middleware,
      userApi.middleware,
      sessionMiddleware, // for session check each api response
    ]),
});

//todo:
// function saveToLocalStorage(state) {
//     try {
//         const serialState = JSON.stringify(state)
//         localStorage.setItem("reduxStore", serialState)
//     } catch (e) {
//         console.warn(e);
//     }
// }

//todo:
// function loadFromLocalStorage() {
//     try {
//         const serialisedState = localStorage.getItem("reduxStore");
//         if (serialisedState === null) return undefined;
//         return JSON.parse(serialisedState);
//     } catch (e) {
//         console.warn(e);
//         return undefined;
//     }
// }

//todo:
// store.subscribe(() => saveToLocalStorage(store.getState()));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch) //todo:
