import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';

/**Import Custom Component */
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import Navbar from "./Navbar";

// Icons
import { FaUser } from "react-icons/fa";
import { IoIosSettings } from "react-icons/io";
import { IoNotifications } from "react-icons/io5";
import { MdDashboard } from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import { removeToken } from "../../Data/services/localStorageService";
import { GoAlertFill, GoScreenNormal } from "react-icons/go";
import { TbEmergencyBed } from "react-icons/tb";
import Toast from "../../Hooks/useToast";
import LogoutModal from "../Modals/LogoutModal";
import logo from "../../assets/images/logo.png";
import SideListView from "./SideListView";
import { Form } from "react-bootstrap";
import { FcHeatMap } from "react-icons/fc";
import { FaEdgeLegacy } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toggleHeatMap } from "../../redux/slices/heatMap";
import SettingModal from "../Modals/SettingModal";

export default function ProtectedLayout({
  isSearchBar,
  isSettingButton,
  selectedRoute,
  children,
  onBooking,
}) {
  const [collapsed, setCollapsed] = useState(window.innerWidth > 991);
  const [sideViewopened, setSideViewopened] = useState(
    onBooking ? onBooking : false
  );
  const [logoutModal, setLogoutModal] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const heatMap = useSelector((state) => state.heatMap);

  const handleHeatMapToggle = () => {
    dispatch(toggleHeatMap());
  };

  const handleToggleLegacyView = (event) => {
    const isChecked = event.target.checked;
    console.log(isChecked);

    if (isChecked) {
      navigate("/booking-history");
    } else {
      navigate("/dashboard");
    }

    setSideViewopened(isChecked);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setCollapsed(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sidelinks = [
    {
      label: "Dashboard",
      icon: <MdDashboard />,
    },
    {
      label: "Normal",
      icon: <GoScreenNormal />,
    },
    {
      label: "Security Check",
      icon: <GoAlertFill />,
    },
    {
      label: "Emergency",
      icon: <TbEmergencyBed />,
    },
    // {
    //   label: "Settings",
    //   icon: <GoScreenNormal />,
    // },
  ];

  const sidelinkbooking = [
    {
      label: "Dashboard",
      icon: <MdDashboard />,
    },
    // {
    //   label: "Settings",
    //   icon: <GoScreenNormal />,
    // },
  ];

  const items = onBooking ? sidelinkbooking : sidelinks;
  // todo: menu

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClose = () => {
    setCollapsed(false);
  };

  const handleViewProfile = () => {
    navigate(baseRoute(WEB_ROUTES.PROFILE));
  };

  const handleLogoutModalOpen = () => {
    setLogoutModal(true);
  };

  const handleSettingModal = () => {
    setSettingModal((prev) => !prev);
  };

  const handleLogoutModalClose = () => {
    setLogoutModal(false);
  };

  const handleLogout = () => {
    try {
      removeToken();
      navigate("/");
      window.location.reload();
      Toast("Logged Out Successfully", "success");
    } catch {
      Toast("An error occurred during log out", "error");
    }
  };

  return (
    <div className={styles.ProtectedLayout}>
      <div
        className={collapsed ? styles.background : ""}
        onClick={handleMenuClose}
      ></div>
      <div
        className={`${styles.menu} ${
          collapsed ? `${styles.collapsed} visible` : styles.notCollapsed
        }`}
        id="sider"
      >
        <Link
          className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ""}`}
          to={`${process.env.REACT_APP_DOMAIN_DIR}/booking-history`}
        >
          <img
            src={logo} //todo:
            alt="Logo"
            className={
              collapsed ? styles.imageCollapsed : styles.imageNotCollapsed
            }
            width={"100%"}
            height={"60%"}
          />
        </Link>

        {/* {
          !onBooking && (
            <p
            onClick={() => setSideViewopened((prev) => !prev)}
            className={`mb-3 fs-4 pointer text-end pr-3`}
          >
            {sideViewopened ? <RxDoubleArrowLeft /> : <RxDoubleArrowRight />}
          </p>
          )
        } */}

        {items.map(({ icon, label }, index) => {
          const getPath = (label, sideViewopened) => {
            if (label === "Legacy" || label === "Heat Map") return "";
            if (sideViewopened && label === "Dashboard")
              return "booking-history";
            return label.toLowerCase().replace(/[^A-Z0-9]/gi, "-");
          };
          return (
            <Link
              // to={
              //   `${process.env.REACT_APP_DOMAIN_DIR || ""}/${
              //   !(label === "Legacy" || label === "Heat Map")
              //     ? (sideViewopened && label === label.toLowerCase().replace(/[^A-Z0-9]/gi, "-") )
              //     : ""
              // }`}
              to={`${process.env.REACT_APP_DOMAIN_DIR || ""}/${getPath(
                label,
                sideViewopened
              )}`}
              className={`${styles.item} ${
                index === selectedRoute ? styles.active : ""
              }`}
              key={index}
              // onClick={() => {
              //   if (label === "Dashboard") {
              //     setSideViewopened(false);
              //   }
              // }}
            >
              {/* <span style={{ fontSize: "20px", display: "flex" }}>
                {" "}
                {icon}{" "}
              </span> */}
              {collapsed ? <p>{label}</p> : ""}
            </Link>
          );
        })}
        <Link
          className={styles.item}
          onClick={handleSettingModal}
          // onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())}
        >
          {/* <img src={logout} alt="Icon1" width={20} height={20} /> */}
          {/* <span style={{ fontSize: "20px", display: "flex" }}>
            {" "}
            <TbLogout2 />{" "}
          </span> */}
          {collapsed ? <p>Settings</p> : ""}
        </Link>
        <div
          className={`d-none d-lg-flex justify-content-between ${styles.item}`}
        >
          {/* <span style={{ fontSize: "20px", display: "flex" }}>
            {" "}
            <FaEdgeLegacy />{" "}
          </span> */}
          <p>Legacy</p>
          <span
            style={{ fontSize: "20px", display: "flex", textAlign: "center" }}
          >
            <Form>
              <Form.Check
                onChange={handleToggleLegacyView}
                type="switch"
                defaultChecked={onBooking}
                checked={sideViewopened}
              />
            </Form>
          </span>
        </div>
        {/* {onBooking && ( */}
        <div className={`d-flex justify-content-between ${styles.item}`}>
          {/* <span
              style={{ fontSize: "20px", display: "flex", textAlign: "center" }}
            >
              {" "}
              <FcHeatMap className="text-black" />{" "}
            </span> */}
          <p>Heat Map</p>
          <span
            style={{ fontSize: "20px", display: "flex", textAlign: "center" }}
          >
            <Form>
              <Form.Check
                type="switch"
                onChange={handleHeatMapToggle}
                checked={heatMap}
              />
            </Form>
          </span>
        </div>
        {/* )} */}
        <Link
          className={styles.item}
          onClick={handleLogoutModalOpen}
          // onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())}
        >
          {/* <img src={logout} alt="Icon1" width={20} height={20} /> */}
          {/* <span style={{ fontSize: "20px", display: "flex" }}>
            {" "}
            <TbLogout2 />{" "}
          </span> */}
          {collapsed ? <p>Logout</p> : ""}
        </Link>
      </div>
      <div className={styles.routeContent}>
        {logoutModal ? (
          <LogoutModal
            handleLogoutModalClose={handleLogoutModalClose}
            data={""}
            onLogout={handleLogout}
          />
        ) : null}
        <Navbar
          isSearchBar={isSearchBar}
          isSettingButton={isSettingButton}
          handleToggle={handleToggle}
          title={"Dispatcher Dashboard"}
          collapsed={collapsed}
        />
        <div className={styles.sideviewContent}>
          {sideViewopened && (
            <SideListView
              handleToggle={setCollapsed}
              collapsed={collapsed}
              children={children}
            />
          )}
          {settingModal ? (
            <SettingModal handleSettingModal={handleSettingModal} />
          ) : null}
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}
