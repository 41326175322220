import React, { useState } from 'react'
import DashboardLayout from '../../Components/Layouts/DashBoardLayout'
import { CardTitle } from '../../Components/CustomComponents'
import DispatcherCard from '../../Components/Cards/DispatcherCard'
import { Col, Row } from 'react-bootstrap'
import { dispachterdata } from '../Dashboard'
import AlertDispatcherModal from '../../Components/Modals/AlertDispatcherModal'

const Alert = () => {
    const filteredAlertDispatcher = dispachterdata.filter(item => item.beat_count > 150 && item.beat_count < 190 );
    const [alertModal, setAlertModal] = useState(false)

    const handleModalClose = () => {
        setAlertModal(prev => !prev);
  };
  
  return (
    <DashboardLayout>
        <section>
        <CardTitle text='Security Check' classes="text-black fs-5 fw-bold mb-2"/>
        <Row className={`mb-5`}>
        {dispachterdata.length > 0 && (
            filteredAlertDispatcher.map((item) => (
                <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6} key={item.id} onClick={handleModalClose}>
                <DispatcherCard data={item} />
                </Col>
            ))
        )}
        </Row>
        </section>
        {alertModal ? (
          <AlertDispatcherModal
            handleClose={handleModalClose}
            data={''}
          />
        ) : null}
    </DashboardLayout>
  )
}

export default Alert