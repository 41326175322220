import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import { ReduxProvider } from './redux/reduxProvider.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store } from './store.js';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <ReduxProvider>
        <ToastContainer />
        <App />
      </ReduxProvider>
  </React.StrictMode>
);

reportWebVitals();
