import React, { useRef, useState } from "react";
import styles from "./styles/Navbar.module.scss";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import { NotificationIcon } from "../../Components/notificationIcon";
import Notification from "../Navbar/Notification";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import avatar from "../../assets/images/Avatar.svg";
import logo from "../../assets/images/logo.png";

export default function Navbar({
  isSearchBar = true,
  isSettingButton = false,
  handleToggle,
  title,
  collapsed
}) {
  const [notiModal, setNotiModal] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  return (
    <div className={`${styles.Navbar} `}>
      <div className="d-none d-lg-flex">
      {!collapsed && (
        <Link
          className={`${styles.logo}`}
          to={`${process.env.REACT_APP_DOMAIN_DIR}/booking-history`}
        >
          <img
            src={logo} //todo:
            alt="Logo"
            className={styles.imageNotCollapsed}
            width={"100%"}
            height={"60%"}
          />
        </Link>
      )}
      <div className={styles.titleContainer}>
        <span
          onClick={handleToggle}
          className={`d-block d-lg-none ${styles.toggleButton}`}
        >
          <GiHamburgerMenu />
        </span>
        <h3 className={`${styles.title} ml-sm-2`}>{title}</h3>
      </div>
      </div>
      <div className={`d-block d-lg-none ${styles.titleContainer}`}>
      <h3 className={`${styles.title} ml-sm-2`}>{title}</h3>
      </div>
      {isSearchBar ? (
        <div className={styles.search}>
          <div className={styles.searchButton}>
            <AiOutlineSearch />
          </div>
          <input
            type="text"
            placeholder="Search "
            // value={search}
            // onChange={(e) => dispatch(setValue(e.target.value))}
          />
        </div>
      ) : (
        ""
      )}

      <div
        className={`${styles.navbaricons} d-flex align-items-center justify-content-between gap-lg-2`}
      >
        <div className={styles.notiIcon}>
          <div onClick={() => setNotiModal(!notiModal)}>
            <NotificationIcon />
          </div>

          <Notification notiModal={notiModal} setNotiModal={setNotiModal} />
        </div>
        <div className="profile-detail d-flex align-items-center gap-2 mr-2">
          <img
            src={avatar}
            alt="Profile"
            className="profile-img"
            style={{ height: "34px", width: "34px" }}
          />
          <div className="name d-lg-block d-none">Sarah Adam</div>
        </div>
        <span
          onClick={handleToggle}
          className={`d-block d-lg-none ${styles.toggleButton}`}
        >
          <GiHamburgerMenu />
        </span>
      </div>
    </div>
  );
}
