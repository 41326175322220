import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadedImages: [],
  selectedImages: [],
  caption: '',
  location: '',
};

const CreatePostSlice = createSlice({
  name: 'createpost',
  initialState,
  reducers: {
    uploadImages: (state, action) => {
      state.uploadedImages = action.payload;
    },
    selectImages: (state, action) => {
      state.selectedImages = action.payload;
    },
    removeSelectedImage: (state, action) => {
      state.selectedImages = state.selectedImages.filter(
        (image) => image !== action.payload
      );
    },
    setCaption: (state, action) => {
      state.caption = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    resetPostState: (state) => {
      state.uploadedImages = [];
      state.selectedImages = [];
      state.caption = '';
      state.location = '';
    },
  },
});

export const {
  uploadImages,
  selectImages,
  removeSelectedImage,
  setCaption,
  setLocation,
  resetPostState,
} = CreatePostSlice.actions;

export const CreatePostReducer =  CreatePostSlice.reducer;
