import React, { useState } from "react";
import DashboardLayout from "../../Components/Layouts/DashBoardLayout";
import { CardTitle } from "../../Components/CustomComponents";
import profile from "../../assets/images/dispatcher_profile.png";
import DispatcherCard from "../../Components/Cards/DispatcherCard";
import { Col, Row } from "react-bootstrap";
import VitalsModal from "../../Components/Modals/VitalModal";
import NomalDispatcherModal from "../../Components/Modals/NormalModal";
import AlertDispatcherModal from "../../Components/Modals/AlertDispatcherModal";

export const dispachterdata = [
  {
    id: 0,
    profile_name: "Carlous Harel",
    profile_img: profile,
    beat_count: 190,
  },
  {
    id: 1,
    profile_name: "Jane Doe",
    profile_img: profile,
    beat_count: 180,
  },
  {
    id: 2,
    profile_name: "John Doe",
    profile_img: profile,
    beat_count: 170,
  },
  {
    id: 3,
    profile_name: "Emma Watson",
    profile_img: profile,
    beat_count: 160,
  },
  {
    id: 4,
    profile_name: "Michael Johnson",
    profile_img: profile,
    beat_count: 150,
  },
  {
    id: 5,
    profile_name: "Sarah Brown",
    profile_img: profile,
    beat_count: 140,
  },
  {
    id: 6,
    profile_name: "David Smith",
    profile_img: profile,
    beat_count: 130,
  },
  {
    id: 7,
    profile_name: "Emily Davis",
    profile_img: profile,
    beat_count: 120,
  },
  {
    id: 8,
    profile_name: "Michael Wilson",
    profile_img: profile,
    beat_count: 110,
  },
  {
    id: 9,
    profile_name: "Michael Wilson",
    profile_img: profile,
    beat_count: 116,
  },
  {
    id: 10,
    profile_name: "Daniel Lee",
    profile_img: profile,
    beat_count: 100,
  },
];
export const filteredEmergencyDispatcher = dispachterdata.filter(
  (item) => item.beat_count >= 190
);
export const filteredAlertDispatcher = dispachterdata.filter(
  (item) => item.beat_count > 150 && item.beat_count < 190
);
export const filteredNormalDispatcher = dispachterdata.filter(
  (item) => item.beat_count < 150 && item.beat_count > 60
);
const Dashboard = () => {
  const [vitalModal, setVitalModal] = useState(false);
  const [normalModal, setNormalModal] = useState(false)
  const [alertModal, setAlertModal] = useState(false)

  const handleModalClose = () => {
    setVitalModal(false);
    setNormalModal(false);
    setAlertModal(false);
  };
  return (
    <DashboardLayout>
      <section>
        <CardTitle text="Emergency" classes="text-black fs-5 fw-bold mb-2" />
        <Row className={`mb-5`}>
          {dispachterdata.length > 0 &&
            filteredEmergencyDispatcher.map((item) => (
              <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6} key={item.id} onClick={() => setVitalModal(true)}>
                <DispatcherCard data={item} />
              </Col>
            ))}
          {vitalModal ? (
            <VitalsModal handleClose={handleModalClose} data={""} />
          ) : null}
        </Row>
      </section>
      <section>
        <CardTitle text="Security Check" classes="text-black fs-5 fw-bold mb-2" />
        <Row className={`mb-5`}>
          {dispachterdata.length > 0 &&
            filteredAlertDispatcher.map((item) => (
              <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6} key={item.id} onClick={() => setAlertModal(true)}>
                <DispatcherCard data={item} />
              </Col>
            ))}
        {alertModal ? (
          <AlertDispatcherModal
            handleClose={handleModalClose}
            data={''}
          />
        ) : null}
        </Row>
      </section>
      <section>
        <CardTitle text="Normal" classes="text-black fs-5 fw-bold mb-2" />
        <Row className={`mb-5`}>
          {dispachterdata.length > 0 &&
            filteredNormalDispatcher.map((item) => (
              <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6} key={item.id} onClick={() => setNormalModal(true)}>
                <DispatcherCard data={item} />
              </Col>
            ))}
        {normalModal ? (
          <NomalDispatcherModal
            handleClose={handleModalClose}
            data={''}
          />
        ) : null}
        </Row>
      </section>
    </DashboardLayout>
  );
};

export default Dashboard;
