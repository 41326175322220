import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  name: "",
  permissions: [], // this is direct permission | permissions.map((permiison)=>{permission.name})
  roles: [], // this is roles and permission | roles -> role.name | roles.permissions.map((permiison)=>{permission.name})
  users: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
      state.roles = action.payload.roles;
    },
    unsetUserInfo: (state, action) => {
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
      state.roles = action.payload.roles;
    },
    updateUsers: (state, action) => {
      // console.log('update Users' + action.payload);
      state.users = action.payload;
    },
    // hasPermissionInBoth: (state, action) => {
    //   const hasPermission = hasPermission(permissionName)(state);
    //   if (hasPermission) {
    //     return true;
    //   }
    //   const hasRolePermission = this.hasRolePermission(permissionName)(state);
    //   return hasRolePermission;
    // },
  },
});

/**
 * The function checks if a user has a specific role.
 * @param roleName - The roleName parameter is a string that represents the name of the role that we
 * want to check if the user has.
 * @returns The `hasRole` function is returning another function that takes a `state` parameter and
 * checks if the `state.user.roles` array contains a role with the specified `roleName`. The `some`
 * method returns a boolean value indicating whether at least one element in the array satisfies the
 * provided testing function. Therefore, the `hasRole` function returns a boolean value indicating
 * whether the user has the specified
 */
// export const hasRole = (roleName) => (state) => {
//   return state.user.roles.some((role) => role.name == roleName); /// OK
// };

/**
 * This is a higher-order function that checks if a user has a specific permission.
 * @param permissionName - a string representing the name of the permission that needs to be checked.
 * @returns The `hasPermission` function returns another function that takes a `state` object as an
 * argument and checks if the `state.user.permissions` array contains a permission object with a `name`
 * property that matches the `permissionName` argument passed to the outer function. The inner function
 * returns a boolean value indicating whether the permission exists or not.
 */
export const hasPermission = (permissionName) => (state) => {
  return state.user.permissions.some(
    (permission) => permission.name == permissionName
  ); /// OK
};

/**
 * This function checks if a user has a specific permission based on their roles.
 * @param permissionName - a string representing the name of the permission being checked for.
 * @returns A higher-order function that takes in a state object and returns a boolean value indicating
 * whether the user associated with the state object has a role with the specified permission name.
 */
// export const hasRolePermission = (permissionName) => (state) => {
//   return state.user.roles.some((role) => {
//     return role.permissions.some(
//       (permission) => permission.name === permissionName
//     );
//   });
// };

/**
 * The function checks if a user has a specific permission either directly or through their assigned
 * role.
 * @param permissionName - a string representing the name of the permission to be checked for.
 * @returns A higher-order function `hasPermissionInBoth` is being returned, which takes a
 * `permissionName` argument and returns a function that takes a `state` argument. The returned
 * function checks if the user has the `permissionName` either directly or through their role, and
 * returns `true` if they have it in either way, otherwise it returns `false`.
 */
// export const hasPermissionInBoth = (permissionName) => (state) => {
//   // Import the two functions to be called
//   const checkDirectPermission = hasPermission(permissionName);
//   const checkRolePermission = hasRolePermission(permissionName);

//   // Use the imported functions
//   const hasDirectPermission = checkDirectPermission(state);
//   // Implement the logic of the fourth function
//   if (hasDirectPermission) {
//     return true;
//   }

//   const hasPermissionInRole = checkRolePermission(state);
//   return hasPermissionInRole;
// };

export const { setUserInfo, unsetUserInfo, updateUsers } = userSlice.actions;

export default userSlice.reducer;
