import React from "react";
import styles from "./styles/DashboardCard.module.css";
import { CardTitle } from "../CustomComponents";
import profile from '../../assets/images/dispatcher_profile.png'

const DispatcherCard = ({ data }) => {
  function beatColor(beatCount) {
    if (beatCount >= 190 && beatCount <= 240) {
      return "#FF0000";
    } else if (beatCount >= 150 && beatCount < 190) {
      return "#FE740B";
    } else if (beatCount >= 60 && beatCount < 150) {
      return "#23AD23";
    }
    return "#000";
  }

  return (
    <div className={`${styles.Card}`}>
      <div className="border-end w-50">
        <div className={`${styles.iconCard}`}>
          <img src={profile} alt="Officer" className={styles.profileImage}/>
        </div>
        <CardTitle
          text={data.profile_name}
          classes={`text-black fw-bold mt-2 ${styles.cardText}`}
        />
      </div>
      <div>
        <p className={`mb-4 ${styles.cardText}`}>Heart Beat</p>
        <p
          className="fs-2 fw-bold m-0"
          style={{ color: beatColor(data.beat_count) }}
        >
          {data.beat_count}
          <span className="fs-6 text-black">BMP</span>
        </p>
      </div>
    </div>
  );
};

export default DispatcherCard;
