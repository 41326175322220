import React from "react";
import styles from "./style/HeatMap.module.scss";
import { FaXmark } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const HeatMap = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  const heatMap = useSelector((state) => state.heatMap);

  return (
    <>
      {heatMap && (
        <main className={styles.HeatMap}>
          <p className={`${styles.xmark} bg-opacity-75 d-flex p-3 rounded-5`}>
            <FaXmark
              role="button"
              onClick={handleBack}
              style={{ cursor: "pointer" }}
            />
          </p>
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345092064!2d144.963057615868!3d-37.81627974201416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf577d87cb2b2fddf!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1633044182654!5m2!1sen!2sau"
              width="100%"
              height="100%"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen=""
              loading="lazy"
              title="Map"
            ></iframe>
          </div>
        </main>
      )}
    </>
  );
};

export default HeatMap;
