import React from 'react';
import styles from './styles/DashboardCard.module.css';
import { BiSolidDroplet } from "react-icons/bi";

const DashboardCard = ({ bg1 = '', bg2 = '', icon, number, text, }) => {
    return (
        <div className={`${styles.Card}`}
            // style={{ backgroundImage: `linear-gradient(0deg, ${bg1} 0%, ${bg2} 80%)` }}
            >
            <div>
                <p className='fs-7 mb-2'>{text}</p>
                <p className='fs-1 fw-bold m-0'>{number}</p>
            </div>
            <div className={`${styles.iconCard}`}>
                {icon}
            </div>
        </div>
    )
}

export default DashboardCard
